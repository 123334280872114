<template>
  <div>
    <Modal :value="value" :width="600" title="企业账号" @on-visible-change="modalChange" @input="(val) => $emit('input', val)">
      <!-- 包含养殖户名称、编码、注册地址、是否种养一体、养殖场数量、规模及信息、养殖户联系人、联系方式等信息 -->
      <Form ref="form" :model="form" :label-width="100" :rules="rules">
        <Row>
          <Col span="11">
          <FormItem label="头像：" prop="avatar">
            <upload-image v-model="form.avatar"></upload-image>
          </FormItem>
          </Col>
          <Col span="13">
          <Row>
            <Col span="24">
            <FormItem label="性别：" prop="sex">
              <Select v-model="form.sex" clearable>
                <Option :value="1">男</Option>
                <Option :value="0">女</Option>
              </Select>
            </FormItem>
            </Col>
            <Col span="24">
            <FormItem label="账号：" prop="phone">
              <Input v-model="form.phone" :disabled="!rules.phone"></Input>
            </FormItem>
            </Col>
          </Row>
          </Col>
          <Col span="11">
          <FormItem label="姓名：" prop="name">
            <Input v-model="form.name"></Input>
          </FormItem>
          </Col>
          <Col span="13" style="text-align:center">
          <Poptip confirm transfer title="确定初始化密码吗?" @on-ok="resetPassword">
            <Button icon="md-refresh">初始化密码</Button>
          </Poptip>
          </Col>
        </Row>
      </Form>

      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="submit">确定</Button>
      </p>
    </Modal>

    <picture-view v-model="picture.show" :src="picture.src"></picture-view>
  </div>
</template>

<script>
import pictureView from '@/components/picture_view'
export default {
  components: {
    pictureView,
  },
  data() {
    return {
      form: {},
      picture: {
        show: false,
        src: '',
      },
      rules: {
        name: [{ required: true, message: '请填写姓名' }],
        password: [{ required: true, message: '请输入密码' }],
      },
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    farmersUserId: {
      type: String | Number,
      default: () => {
        return ''
      },
    },
  },
  methods: {
    modalChange(validate) {
      if (validate) {
        this.$get(
          this.$api.FAMRMING.COMPANY_GETUSER + `?companyTemp=${this.farmersUserId}`
        ).then((res) => {
          console.log(res)
          if (res) {
            this.form = res
          } else {
            this.rules.phone = [
              { required: true, message: '请填写联系电话' },
              {
                validator(rule, value, callback) {
                  if (!/^1[3|4|5|7|8|9][0-9]{9}$/.test(value)) {
                    callback(rule.message)
                    return
                  }
                  callback()
                },
                message: '手机号格式不正确',
                trigger: 'change',
              },
            ]
          }
        })
      } else {
        this.form = {}
      }
    },
    //放大查看图片
    lookPicture(url) {
      this.picture = {
        show: true,
        src: url,
      }
    },
    resetPassword() {
      let params = {}
      params.userId = this.form.id
      params.id = this.form.id
      params.companyNo = this.form.companyNo
      this.$post(this.$api.FAMRMING.RESET_PASSWORD, params).then((res) => {
        if (res) {
          this.$Message.success('操作成功')
        } else {
          this.$Message.success('操作成功')
        }
      })
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return
        let params = { ...this.form }
        params.companyTemp = this.farmersUserId
        this.$post(this.$api.FAMRMING.COMPANY_SETUSER, params).then(() => {
          this.$Message.success(params.id ? '修改成功！' : '增加成功！')
          this.$emit('input', false)
          this.getList()
          this.modal.show = false
        })
      })
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ div {
  .ivu-form-item {
    .ivu-form-item-content {
      margin-left: 0 !important;
      display: flex;
    }
  }
  .form-title {
    padding: 10px 20px;
  }
  h4 {
    margin-bottom: 10px;
  }
}
</style>