<template>
  <div class="farm-page">
    <x-table :no-data-text="CA('farm_check') ? '暂无数据' : '暂无数据查询权限'" :columns="table.columns" :data="table.data" :loading="table.loading" :config="config" @add="add" @search="search" @page-change="pageChange" @loadEnter="loadEnter" @loadExpend="loadExpend" @page-size-change="pageSizeChange" :showSummary="true" :summaryMethod="handleSummary"></x-table>

    <Modal v-model="modal.show" :title="modal.title" @on-visible-change="modalShow" :width="900" fullscreen>
      <Form ref="form" :model="form" :rules="rules" :label-width="180">
        <Row>
          <Col span="8">
          <FormItem label="养殖场名称" prop="farmerName">
            <Input clearable v-model="form.farmerName" placeholder="请填写养殖场名称" style="width: 260px"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="养殖场面积" prop="farmerArea">
            <Input clearable v-model="form.farmerArea" placeholder="请填写养殖场面积" style="width: 260px"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="负责人" prop="chargePerson">
            <Input clearable v-model="form.chargePerson" style="width: 260px" placeholder="请填写负责人"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="负责人电话" prop="chargePersonTel">
            <Input clearable v-model="form.chargePersonTel" style="width: 260px" placeholder="请填写负责人电话"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="区域" prop="region">
            <Cascader :data="areaList" v-model="form.region" change-on-select :load-data="loadData" style="width: 260px" placeholder="请选择区域" @on-change="handleCascader"></Cascader>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="详细地址" prop="address">
            <Input clearable v-model="form.address" style="width: 260px" placeholder="请输入详细地址"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="粪污收集工艺" prop="manureProcess">
            <Select v-model="form.manureProcess" style="width: 260px" placeholder="请选择粪污收集工艺" label-in-value @on-change="handleManureProcess">
              <Option v-for="item in manureProcess" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="养殖品种" prop="breedings">
            <Select clearable v-model="form.breedings" style="width: 260px" placeholder="请选择养殖品种 ">
              <Option :value="item.id + '/' + item.name" v-for="(item, index) in catData" :key="index">{{ item.name }}</Option>
            </Select>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="规模类型" prop="scaleInfo">
            <Select clearable palceholder="请选择规模类型" style="width: 260px" v-model="form.scaleInfo" label-in-value @on-change="handleChange">
              <Option v-for="(item, index) in scaleList" :key="index" :value="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="处理方式" prop="useTypes">
            <Select v-model="form.useTypes" multiple style="width: 260px" label-in-value @on-change="handleUseType">
              <Option v-for="item in dealMethod" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="产能配套率" prop="capacityMatchRate">
            <Input clearable v-model="form.capacityMatchRate" style="width: 260px" placeholder="请输入产能配套率"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="土地配套面积" prop="consumptionScale">
            <Input clearable v-model="form.consumptionScale" style="width: 260px" placeholder="请输入土地配套面积"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="养殖用途" prop="application">
            <Select clearable placeholder="请选择养殖用途" v-model="form.application" style="width: 260px" label-in-value @on-change="handleApplication">
              <Option v-for="(item, index) in application" :key="index" :value="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="当前存栏" prop="currentNum">
            <Input style="width: 260px" clearable placeholder="请输入现存栏数量" v-model="form.currentNum">
            <span slot="append">头、羽、只</span>
            </Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="其中能繁母猪" prop="pigNum">
            <Input style="width: 260px" clearable placeholder="请输入其中能繁母猪" v-model="form.pigNum" :disabled="disableds">
            <span slot="append">头</span>
            </Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="建厂时间" prop="createDate">
            <DatePicker type="date" style="width: 260px" placeholder="请选择建厂时间" v-model="form.createDate"></DatePicker>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="是否环保设备设施">
            <RadioGroup v-model="form.environmentallyOk">
              <Radio :label="1">
                <span>是</span>
              </Radio>
              <Radio :label="0">
                <span>否</span>
              </Radio>
            </RadioGroup>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="粪污治理设施是否配套">
            <RadioGroup v-model="form.managementFacilitiesOk">
              <Radio :label="1">
                <span>是</span>
              </Radio>
              <Radio :label="0">
                <span>否</span>
              </Radio>
            </RadioGroup>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="设计年出栏量" prop="ceilingCount">
            <Input clearable v-model="form.ceilingCount" style="width: 260px" placeholder="请输入设计年出栏量"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="设计存栏量" prop="designNum">
            <Input clearable v-model="form.designNum" style="width: 260px" placeholder="请输入设计存栏量"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="离河库最近距离">
            <Input clearable style="width: 260px" v-model="form.riverMeter" placeholder="请输入离河库最近距离">
            <span slot="append">米</span>
            </Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="固液分离">
            <RadioGroup v-model="form.solidLiquidSeparation">
              <Radio :label="1">
                <span>是</span>
              </Radio>
              <Radio :label="0">
                <span>否</span>
              </Radio>
            </RadioGroup>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="养殖场绘制" prop="mapArea">
            <Input v-model="form.mapArea" style="width: 260px" placeholder="请选择养殖场">
            <span slot="append" class="iconfont" style="cursor: pointer" @click="drawMap">&#xe648;</span>
            </Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="粪污处理流程">
            <Select clearable v-model="form.craft" style="width: 260px" placeholder="请选择粪污处理流程">
              <Option v-for="item in manureDealProcess" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="干粪台">
            <Input clearable style="width: 260px" v-model="form.dungDryingTable" placeholder="请输入干粪台面积">
            <span slot="append">平方米</span>
            </Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="还田管网">
            <Input clearable style="width: 260px" v-model="form.returningPipe" placeholder="请输入还田管网长度">
            <span slot="append">米</span>
            </Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="敞口储液设施容积">
            <Input clearable style="width: 260px" v-model="form.liquidStoragePool" placeholder="请输入敞口储液设施容积">
            <span slot="append">立方米</span>
            </Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="密闭储液设施容积">
            <Input clearable style="width: 260px" v-model="form.biogasPool" placeholder="请输入密闭储液设施容积">
            <span slot="append">立方米</span>
            </Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="发酵床容积">
            <Input clearable style="width: 260px" v-model="form.fermentationBed" placeholder="请输入发酵床容积">
            <span slot="append">立方米</span>
            </Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="备注" prop="remark">
            <Input clearable v-model="form.remark" style="width: 260px" placeholder="请输入备注"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="监管人员">
            <Input style="width: 260px" search enter-button="选择" clearable placeholder="请选择监管人员" v-model="form.supervisorName" readonly @on-search="handleSupervise"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="监管人电话">
            <Input style="width: 260px" clearable placeholder="请输入监管人电话" v-model="form.supervisorPhone" disabled></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="兽禽编码">
            <Input style="width: 260px" clearable placeholder="请输入兽禽编码" v-model="form.livestockCode"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="养殖场图片">
            <upload-image v-model="form.picUrl" :max="1" :clear-flag="!modal.show"></upload-image>
          </FormItem>
          </Col>
        </Row>
        <div v-show="form.craft" style="
            width: 100%;
            height: 150px;
            border: 1px dashed #ccc;
            margin-bottom: 24px;
          " id="svga"></div>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="modal.submitLoading" @click="submit">提交</Button>
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>

    <draw-map-modal v-model="drawMapModalShow" @draw-finish="drawFinish" :mapPosition="form.mapArea"></draw-map-modal>

    <loh-information v-model="lohInformationShow" :farm="farm"></loh-information>
    <Modal v-model="farmBlueprintShow" fullscreen>
      <farmBlueprint></farmBlueprint>
    </Modal>
    <Modal v-model="superviseShow" title="选择监管人" width="1200" @on-ok="handleSelect">
      <supervise ref="supervise" @ok="handleOk"></supervise>
    </Modal>

    <picture-view v-model="picture.show" :src="picture.src"></picture-view>
    <farmers-user v-model="farmersUser.show" :farmersUserId="farmersUser.id"></farmers-user>
  </div>
</template>

<script>
import drawMapModal from './drawMapModal'
import CU from '@/common/util'
import lohInformation from '../lohInformation'
import editor from '@/components/editor'
import pictureView from '@/components/picture_view'
import * as SVGA from 'svgaplayerweb'
import farmBlueprint from '../../farmBlueprint/index.vue'
import selectFarmList from '@/components/selectFarm/selectFarmList'
import farmersUser from './farmersUser.vue'
import supervise from './supervise.vue'

export default {
  name: '',
  components: {
    drawMapModal,
    lohInformation,
    editor,
    pictureView,
    farmBlueprint,
    selectFarmList,
    farmersUser,
    supervise,
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: '养殖场图片',
            width: 110,
            key: '',
            render: (h, { row }) => {
              if (!row.imgUrl) return <span>--</span>
              return (
                <img
                  src={this.getImgUrl(row.servicePath, row.imgUrl)}
                  onClick={() => this.lookPicture(row.servicePath + row.imgUrl)}
                  style="width: 35px;height: 35px;cursor:pointer;"
                />
              )
            },
          },
          {
            title: '养殖场名称',
            key: 'name',
            minWidth: 150,
          },
          {
            title: '规模类型',
            key: 'scaleInfoName',
            minWidth: 150,
          },
          {
            title: '镇街',
            key: 'regionCodeName',
            minWidth: 150,
          },
          {
            title: '养殖品种',
            key: 'typeName',
            minWidth: 150,
          },
          {
            title: '养殖用途',
            key: 'applicationName',
            minWidth: 150,
          },
          {
            title: '当前存栏',
            key: 'currentNum',
            minWidth: 150,
          },
          {
            title: '能繁母猪',
            key: 'pigNum',
            minWidth: 150,
          },
          {
            title: '年出栏量',
            width: 100,
            key: 'ceilingCount',
          },
          {
            title: '污粪利用率',
            key: 'zRate',
            minWidth: 150,
            render: (h, { row }) => {
              return <span>{row.zRate + '%'}</span>
            },
          },
          {
            title: '存储量是否超过最大值',
            minWidth: 200,
            key: 'isStorageMax',
            render: (h, { row }) => {
              return <span>{row.isStorageMax == 1 ? '是' : '否'}</span>
            },
          },
          {
            title: '所需设施配置是否低于配置值数据',
            minWidth: 280,
            key: 'isFacilitiesMin',
            render: (h, { row }) => {
              return <span>{row.isFacilitiesMin == 1 ? '是' : '否'}</span>
            },
          },
          // {
          //   title: '粪污处理方式',
          //   minWidth: 120,
          //   key: 'useTypeName',
          // },
          {
            title: '收集工艺',
            width: 100,
            key: 'manureProcessName',
          },
          {
            title: '养殖场面积',
            width: 110,
            key: 'area',
            render: (h, { row }) => {
              return <span>{row.area + '㎡'}</span>
            },
          },
          {
            title: '负责人',
            width: 120,
            key: 'chargePerson',
          },
          {
            title: '负责人电话',
            width: 130,
            key: 'chargePersonTel',
          },
          {
            title: '创建时间',
            width: 180,
            key: 'createTime',
          },
          {
            title: '产能配套率',
            width: 120,
            key: 'capacityMatchRate',
          },
          {
            title: '土地配套面积',
            width: 100,
            key: 'consumptionScale',
          },
          {
            title: '敞口储液设施容积(m³)',
            tooltip: true,
            key: 'liquidStoragePool',
            minWidth: 260,
          },
          {
            title: '密闭储液设施容积(m³)',
            // width: 100,
            key: 'biogasPool',
            minWidth: 200,
          },
          {
            title: '发酵床容积（m³）',
            width: 180,
            key: 'fermentationBed',
          },
          {
            title: '备注',
            width: 180,
            key: 'remark',
          },
          {
            title: '操作',
            width: 260,
            fixed: 'right',
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA('farm_user') && (
                    <a
                      style="margin-right:10px"
                      on-click={() => this.users(row)}
                    >
                      企业账号
                    </a>
                  )}
                  <a
                    style="margin-right: 10px"
                    onClick={() => this.addFarmArea(row)}
                  >
                    养殖区
                  </a>
                  <a
                    style="margin-right: 10px"
                    onClick={() => this.farmBlueprint(row)}
                  >
                    图纸
                  </a>
                  {this.CA('farm_edit') && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {this.CA('farm_del') && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.del(row.id)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                </div>
              )
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },

      modal: {
        show: false,
        title: '',
        submitLoading: false,
      },
      superviseShow: false,

      form: {
        id: '',
        name: '',
        siteType: '2',
        companyNo: '',
        companyNoTemp: '',
        farmerName: '',
        farmerArea: '',
        chargePerson: '',
        chargePersonTel: '',
        regionCode: '',
        region: [],
        address: '',
        manureProcess: '',
        manureProcessName: '',
        type: '',
        typeName: '',
        scaleInfo: '',
        scaleInfoName: '',
        useType: '',
        useTypeName: '',
        capacityMatchRate: '',
        consumptionScale: '',
        application: '',
        applicationName: '',
        currentNum: '',
        pigNun: '',
        createDate: '',
        environmentallyOk: '',
        managementFacilitiesOk: '',
        ceilingCount: '',
        designNum: '',
        riverMeter: '',
        solidLiquidSeparation: '',
        mapArea: '',
        dungDryingTable: '',
        returningPipe: '',
        liquidStoragePool: '',
        biogasPool: '',
        fermentationBed: '',
        remark: '',
        picUrl: '',
        supervisorName: '',
        areaUserId: '',
        supervisorPhone: '',
        livestockCode: '',
        craft: '',
        pigNum: '',
      },
      options: {
        siteType: 2,
        //species: 3,
      },
      farmersUser: {
        show: false,
        id: '',
      },
      rules: {
        farmerName: [{ required: true, message: '请填写养殖场名称' }],
        chargePerson: [{ required: true, message: '请填写负责人' }],
        chargePersonTel: [
          { required: true, message: '请填写联系电话' },
          {
            validator(rule, value, callback) {
              if (CU.validatePhone(value)) {
                callback()
                return
              }
              if (CU.validateLandline(value)) {
                callback()
                return
              }
              callback(rule.message)
            },
            message: '手机号格式不正确',
            trigger: 'change',
          },
        ],
        region: [{ required: true, type: 'array', message: '请选择区域' }],
        address: [{ required: true, message: '请填写养殖地址' }],
        manureProcess: [{ required: true, message: '请选择粪污类型' }],
        breedings: [{ required: true, message: '请选择养殖品种' }],
        scaleInfo: [{ required: true, message: '请选择规模类型' }],
        consumptionScale: [{ required: true, message: '请填写土地配套面积' }],
        application: [{ required: true, message: '请选择养殖用途' }],
      },
      drawMapModalShow: false,

      dealModal: false,
      dealForm: {
        farmId: '',
        siteType: '2',
        useType: [],
      },
      dealRules: {
        useType: [{ required: true, type: 'array', message: '请选择处理方式' }],
      },
      dealMethod: [],
      dealLoading: false,
      search_data: {},
      manureProcess: [],
      // 粪污处理工艺流程
      manureDealProcess: [],
      svgaPlayer: null,
      lohInformationShow: false,
      farm: {},
      //区域列表
      areaList: [],
      remark: '',
      farmId: '',
      editorLoading: false,
      picture: {
        show: false,
        src: '',
      },
      farmBlueprintShow: false,
      scaleList: [],
      area: [],
      catData: [],
      application: [],
    }
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: '新增养殖场',
            ca: 'farm_add',
          },
          loadModel: {
            url: 'https://castoff.hogdata.cn/app/规模养殖场粪污资源化利用情况调查表.xlsx',
          },
          loadEnter: {
            loadName: '导入',
            ca: 'farm_import',
          },
          loadExpend: {
            loadExpendName: '导出',
            ca: 'farm_export',
          },
          width: 220,
          filterBox: [
            {
              conditionName: '养殖户名称',
              component: 'input',
              field: 'name',
              defaultValue: '',
            },
            {
              conditionName: '规模类型',
              component: 'select',
              field: 'scaleInfo',
              defaultValue: '',
              data: this.scaleList,
              parameterField: 'id',
              showField: 'name',
              clearable: true,
            },
            {
              conditionName: '镇街',
              component: 'cascader',
              field: 'regionCode',
              defaultValue: [],
              data: this.area,
              isLoadData: true,
              loadData: this.loadData2,
            },
            {
              conditionName: '养殖品种',
              component: 'select',
              field: 'type',
              defaultValue: '',
              data: this.catData,
              parameterField: 'id',
              showField: 'name',
              clearable: true,
            },
          ],
        },
        page: { ...this.page },
      }
      return config
    },
    disableds() {
      let bool = true
      this.form.breedings?.split('/').map((item) => {
        if (item.indexOf('猪') != -1) {
          bool = false
        }
      })
      return bool
    },
  },
  watch: {
    'form.craft': {
      handler(val) {
        if (!val) return
        let item = this.manureDealProcess.find((item) => item.id == val)
        if (!item) return
        let url = item.description
        if (!this.svgaPlayer) this.svgaPlayer = new SVGA.Player('#svga')
        let parser = new SVGA.Parser('#svga')
        parser.load(url, (videoItem) => {
          this.svgaPlayer.setVideoItem(videoItem)
          this.svgaPlayer.startAnimation()
        })
      },
    },
  },
  methods: {
    pageChange(pageNo) {
      this.page.pageNo = pageNo
      this.getList()
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize
      this.getList()
    },

    search(value) {
      let n
      if (value.regionCode) {
        for (let i = 0; i < value.regionCode.length; i++) {
          n = value.regionCode[value.regionCode.length - 1]
        }
        let obj = {
          ...value,
          regionCode: n,
        }
        this.search_data = obj
        this.getList()
      } else {
        let obj = {
          ...value,
        }
        this.search_data = obj
        this.getList()
      }
    },

    add() {
      this.modal = {
        show: true,
        title: '新增养殖场',
        submitLoading: false,
      }
    },
    drawMap() {
      this.drawMapModalShow = true
    },
    drawFinish(data) {
      this.form.mapArea = JSON.stringify(data)
      let center = data.length > 0 ? JSON.parse(data[0].options.center) : {}
      this.form.thirdLongLat = center.lng + ',' + center.lat
      this.drawMapModalShow = false
    },

    getList() {
      if (!this.CA('farm_check')) return
      this.table.loading = true
      this.$post(this.$api.BASE_MANAGE.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        siteType: '2',
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list
          this.page.total = +res.total
        })
        .finally(() => {
          this.table.loading = false
        })
    },
    getManureProcess() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: '25',
      }).then((res) => {
        this.manureProcess = res.list
      })
    },
    // 获取粪污处理流程
    getManureDealProcess() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: '261',
      }).then((res) => {
        this.manureDealProcess = res.list
      })
    },
    addFarmArea(row) {
      this.lohInformationShow = true
      this.farm = row
    },
    farmBlueprint(row) {
      this.farmBlueprintShow = true
    },
    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key]
      }
      this.form.picUrl = this.getImgUrl(row.servicePath, row.picUrl)
      this.form.region =
        row.regionCode.indexOf(',') != -1
          ? row.regionCode.split(',')
          : [row.regionCode]
      if (row.useType) {
        this.form.useTypes = row.useType.split(',')
      }
      this.form.companyNoTemp = this.form.companyNo
      this.$set(this.form, 'breedings', row.type + '/' + row.typeName)
      this.modal = {
        show: true,
        title: '编辑养殖场',
        submitLoading: false,
      }
    },

    del(id) {
      this.$post(this.$api.BASE_MANAGE.DELETE, {
        id,
      }).then(() => {
        this.$Message.success('删除成功')
        this.getList()
      })
    },
    //企业账号
    users(row) {
      this.farmersUser = {
        show: true,
        id: row.companyNo,
      }
    },

    //获取区域列表
    getAreaList() {
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: 100000,
      }).then((res) => {
        res.forEach((item) => {
          item.label = item.name
          item.value = item.id
          item.loading = false
          item.children = []
        })
        this.areaList = res
      })
    },
    handleCascader(v, o) {
      console.log(v, o)
    },
    modalShow(visible) {
      if (visible) return
      this.form = {
        id: '',
        name: '',
        siteType: '2',
        companyNo: '',
        companyNoTemp: '',
        farmerName: '',
        farmerArea: '',
        chargePerson: '',
        chargePersonTel: '',
        regionCode: '',
        region: [],
        address: '',
        manureProcess: '',
        manureProcessName: '',
        type: '',
        typeName: '',
        scaleInfo: '',
        scaleInfoName: '',
        useType: '',
        useTypeName: '',
        capacityMatchRate: '',
        consumptionScale: '',
        application: '',
        applicationName: '',
        currentNum: '',
        pigNun: '',
        createDate: '',
        environmentallyOk: '',
        managementFacilitiesOk: '',
        ceilingCount: '',
        designNum: '',
        riverMeter: '',
        solidLiquidSeparation: '',
        mapArea: '',
        dungDryingTable: '',
        returningPipe: '',
        liquidStoragePool: '',
        biogasPool: '',
        fermentationBed: '',
        remark: '',
        picUrl: '',
        supervisorName: '',
        areaUserId: '',
        supervisorPhone: '',
        livestockCode: '',
        craft: '',
        pigNum: '',
      }
      this.$refs.form.resetFields()
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return
        let params = { ...this.form }
        this.form.picUrl &&
          (params.picUrl = 'image' + this.form.picUrl.split('image')[1])
        params.regionCode = this.form.region.join(',')
        delete params.region
        if (this.form.breedings) {
          params.type = this.form.breedings.split('/')[0]
          params.typeName = this.form.breedings.split('/')[1]
          params.pigNum =
            params.typeName.indexOf('猪') != -1 ? params.pigNum : ''
        }
        if (this.form.useTypes?.length > 0) {
          params.useType = this.form.useTypes.join(',')
          delete params.useTypes
        }
        this.modal.submitLoading = true
        this.$post(
          params.id ? this.$api.BASE_MANAGE.UPDATE : this.$api.BASE_MANAGE.ADD,
          params
        )
          .then((res) => {
            this.getList()
            if (res?.data?.code == 500) {
              this.$Message.warning(res.data.message)
            } else {
              this.modal.show = false
            }
          })
          .finally(() => {
            this.modal.submitLoading = false
          })
      })
    },
    handleSummary({ columns, data }) {
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '统计',
          }
          return
        }
        const values = data.map((item) => Number(item[key]))
        if (
          column.key == 'area' ||
          column.key == 'count' ||
          column.key == 'ceilingCount' ||
          column.key == 'consumptionScale'
        ) {
          if (!values.every((value) => isNaN(value))) {
            const v = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[key] = {
              key,
              value: v,
            }
          } else {
            sums[key] = {
              key,
              value: '',
            }
          }
        } else {
          sums[key] = {
            key,
            value: '',
          }
        }
      })
      return sums
    },
    getDealMethod() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: '20',
      }).then((res) => {
        this.dealMethod = res.list
      })
    },

    lookPicture(url) {
      this.picture = {
        show: true,
        src: url,
      }
    },
    //导入
    loadEnter() {
      let input = document.createElement('input')
      input.type = 'file'
      input.onchange = (e) => {
        if (!input.files || input.files.length === 0) return
        //通过FormData构造函数创建一个空对象
        let formdata = new FormData()
        //通过append()方法来追加数据
        formdata.append('file', input.files[0])
        formdata.append('userId', localStorage.getItem('userId'))
        formdata.append('companyNo', localStorage.getItem('companyNo'))
        formdata.append('category', this.user.category + 1)
        this.$post(this.$api.FAMRMING.UPLOAD, formdata, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(() => {
          this.$Message.success('导入成功')
          this.getList()
        })
      }
      input.click()
    },
    //导出
    loadExpend() {
      let params = {
        ...this.search_data,
      }
      let url = this.$api.BASE_MANAGE.UPLOAD
      this.$download(url, params, '养殖户企业')
    },

    //规模类型
    getScale() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: 291,
      }).then((res) => {
        this.scaleList = res.list
      })
    },
    //养殖品种
    getCategory() {
      this.$post(this.$api.PRODUCT_CATEGORY.LIST, {
        type: '2',
      }).then((res) => {
        this.catData = res.list
      })
    },
    //省市区列表接口
    getArea() {
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: '500100',
      }).then((res) => {
        res.forEach((item) => {
          item.label = item.name
          item.value = item.id
          if (item.hasChild == 'true') {
            item.loading = false
            item.children = []
          }
        })
        this.area = res
      })
    },
    //养殖用途
    getApplication() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: '526',
      }).then((res) => {
        this.application = res.list
      })
    },
    loadData2(item, callback) {
      item.loading = true
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: item.id,
      })
        .then((res) => {
          res.forEach((item) => {
            item.label = item.name
            item.value = item.id
          })
          item.children = res
          callback()
        })
        .finally(() => {
          item.loading = false
        })
    },
    loadData(item) {
      item.loading = true
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: item.id,
      })
        .then((res) => {
          res.forEach((item) => {
            item.label = item.name
            item.value = item.id
            if (item.hasChild == 'true' && item.id < 600000) {
              item.loading = false
              item.children = []
            }
          })
          item.children = res
        })
        .finally(() => {
          item.loading = false
        })
    },
    handleChange(o) {
      if (o?.label) {
        this.form.scaleInfoName = o.label
      }
    },
    handleManureProcess(o) {
      if (o?.label) {
        this.form.manureProcessName = o.label
      }
    },
    handleUseType(o) {
      if (o?.label) {
        this.form.useTypeName = o.label
      }
    },
    handleApplication(o) {
      if (o?.label) {
        this.form.applicationName = o.label
      }
    },
    handleSupervise() {
      this.superviseShow = true
      this.$nextTick((r) => {
        this.$refs.supervise.regionCodes =this.form.region
        this.$refs.supervise.selectData.userId = this.form.areaUserId
        this.$refs.supervise.getList()
      })
    },
    handleSelect(v, o) {
      this.$refs.supervise.submit()
    },
    handleOk(data) {
      this.form.supervisorName = data.name
      this.form.areaUserId = data.userId
      this.form.supervisorPhone = data.tel
    },
  },
  mounted() {
    this.getList()
    this.getArea()
    this.getScale()
    this.getAreaList()
    this.getCategory()
    this.getDealMethod()
    this.getApplication()
    this.getManureProcess()
    this.getManureDealProcess()
  },
}
</script>

<style lang="less" scoped>
.farm-page {
  width: 100%;
  height: 100%;
}
</style>